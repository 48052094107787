import Link from "next/link";
import Icon from "@/components/primitives/icon";
import Form from "@/components/primitives/form";
import Button from "../primitives/button";
import { FooterNewsletter } from "../global/newsletter";
import Feedback from "./feedback";
import SportsDataLogo from "./sports-data-icon";

interface FooterMenu {
  title: string;
  items: FooterLink[];
}

interface FooterLink {
  label: string;
  href: string;
  truncated?: string;
}

const FOOTER_MENUS: FooterMenu[] = [
  {
    title: "Sports",
    items: [
      {
        label: "NFL",
        href: "/nfl",
      },
      {
        label: "NBA",
        href: "/nba",
      },
      {
        label: "MLB",
        href: "/mlb",
      },
      {
        label: "NHL",
        href: "/nhl",
      },
    ],
  },
  {
    title: "Odds",
    items: [
      {
        label: "All Odds",
        truncated: "All",
        href: "/odds",
      },
      {
        label: "NFL Odds",
        truncated: "NFL",
        href: "/nfl/odds",
      },
      {
        label: "NBA Odds",
        truncated: "NBA",
        href: "/nba/odds",
      },
      {
        label: "MLB Odds",
        truncated: "MLB",
        href: "/mlb/odds",
      },
      {
        label: "NHL Odds",
        truncated: "NHL",
        href: "/nhl/odds",
      },
    ],
  },
  {
    title: "Picks",
    items: [
      {
        label: "All Picks",
        truncated: "All",
        href: "/picks",
      },
      {
        label: "NFL Picks",
        truncated: "NFL",
        href: "/nfl/picks",
      },
      {
        label: "NBA Picks",
        truncated: "NBA",
        href: "/nba/picks",
      },
      {
        label: "MLB Picks",
        truncated: "MLB",
        href: "/mlb/picks",
      },
      {
        label: "NHL Picks",
        truncated: "NHL",
        href: "/nhl/picks",
      },
    ],
  },
  {
    title: "Quick Links",
    items: [
      {
        label: "How Moneyline Works",
        href: "/how-moneyline-works",
      },
      {
        label: "Sports Betting 101",
        href: "/betting-guide/sports-betting-101",
      },
      {
        label: "FAQ",
        href: "/betting-guide/faq",
      },
      {
        label: "All Sportsbooks",
        href: "/sportsbooks",
      },
      {
        label: "Leaderboard",
        href: "/leaderboard",
      },
    ],
  },
];
const FooterSiteLinks = [
  {
    label: "Sitemap",
    href: "/sitemap",
  },
  {
    label: "Terms of Service",
    href: "/terms-conditions",
  },
  {
    label: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    label: "Contact Us",
    href: "/contact",
  },
];
function Footer() {
  return (
    <footer className="bg-secondary-100 text-secondary-400 w-full overflow-hidden relative pt-12 pb-8 px-4 desktop-grid:px-0">
      <div className="container">
        <div className="flex flex-col desktop-grid:flex-row flex-wrap gap-8 md:gap-12 justify-between">
          <div className="flex flex-row gap-4 md:flex-col justify-between md:justify-start">
            <div className="flex flex-col">

            <Link href="/" prefetch={false} className="select-none">
              <img src="/images/logo.svg" alt="Moneyline Logo" />
            </Link>
            <div className="text-title-sm tracking-[-.01em] mt-2 text-primary select-none">
              Pick with the best.
            </div>
                </div>
                <div className="flex flex-col">

            {/* <Social /> */}

            <div className="flex gap-3 items-center">
              <Link target="_blank" href={"https://www.instagram.com/moneylinecom/"} prefetch={false}>
                <div className="w-8 h-8 rounded-full flex justify-center items-center bg-dark hover:bg-primary-300 group btn">
                  <Icon
                    name="instagram"
                    className="fill-primary w-[0.88rem] h-[0.88rem] group-hover:fill-white"
                    />
                </div>
              </Link>
              <Link target="_blank" href={"https://x.com/moneylinedotcom"} prefetch={false}>
                <div className="w-8 h-8 rounded-full flex justify-center items-center bg-dark hover:bg-primary-300 group btn">
                  <Icon
                    name="x"
                    className="text-primary w-[0.85rem] h-[0.85rem] group-hover:text-white"
                    />
                </div>
              </Link>
            </div>
            <div className="mt-4">

            <SportsDataLogo />
            </div>
                    </div>
          </div>
          <div>
            <FooterNav />
          </div>

          <div>
            {/* <Newsletter /> */}
            <div className="flex flex-col gap-4 ">
              <div className="text-title-sm-bold text-white uppercase">
                Join the moneyline newsletter
              </div>
              <FooterNewsletter />
              <span className="text-xs text-white">
                By subscribing you agree to our{" "}
                <Button
                  variant="underline"
                  className="text-white decoration-white"
                >
                  Privacy Policy
                </Button>
              </span>
              <Feedback color="dark" />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-8 desktop-grid:mt-[72px]">
        <div className="flex flex-wrap justify-between gap-2 desktop-grid:gap-6">
          <div className="flex flex-row flex-wrap w-full">
            <FooterSiteLinksNav />
          </div>

          <div>
            <span className="text-[12px] tracking-wide">
              Copyright {new Date().getFullYear()} © Moneyline, All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="h-[1px] border-t-[1px] border-t-[#232528] mt-6 mb-6"></div>
      </div>
      <div className="container">
        <div className="text-text-gray text-[12px] tracking-wide">
          <p className="mb-6">
            If you intend to utilize any information or online sports betting
            services provided by any websites featured on Moneyline, it is
            strongly advised that you verify your local laws before proceeding.
            It is solely your responsibility to comprehend and strictly follow
            local laws. Moneyline does not provide any guidance or
            recommendations regarding the legality of online sports betting or
            other online gambling activities within your jurisdiction, and you
            are accountable for complying with the relevant laws in your
            locality. Moneyline disclaims all responsibility associated with
            your use of this website and any information contained therein. By
            using this website, you consent to indemnify the owner of this
            website from any claims arising from your utilization of any
            services on any third-party website that may be presented by
            Moneyline.
          </p>
          <p>
            DISCLAIMER: Moneyline is strictly a sports information website.
            Moneyline does not offer any betting, booking making, or gambling | <a href="https://blackflagcreative.com/services" target="_blank" className="hover:text-white transition duration-150 ease-out hover:ease-in">Crafted</a> by <a href="https://blackflagcreative.com/" target="_blank" className="hover:text-white transition duration-150 ease-out hover:ease-in">Black Flag Creative</a>
          </p>
        </div>
      </div>
    </footer>
  );
}

function FooterMenu({ menu }: { menu: FooterMenu }) {
  return (
    <div>
      <div className="text-white uppercase text-title-sm-bold mb-3">
        {menu.title}
      </div>
      <ul
        className={`flex justify-start ${
          menu.title !== "Quick Links"
            ? "flex-row desktop-grid:flex-col"
            : "flex-col flex-wrap desktop-grid:flex-col desktop-grid:flex-nowrap"
        } gap-3`}
      >
        {menu.items.map((item, i) => (
          <li key={i}>
            <Link
              href={item.href}
              prefetch={false}
              className="text-secondary-400 hover:text-white transition-colors"
            >
              <span className="hidden desktop-grid:inline-block">
                {item.label}
              </span>
              <span className="inline-block desktop-grid:hidden">
                {item.truncated || item.label}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

function FooterNav() {
  return (
    <div className="flex flex-col gap-8 md:gap-12 desktop-grid:flex-row desktop-grid:gap-16">
      {FOOTER_MENUS.map((menu, i) => (
        <FooterMenu key={i} menu={menu} />
      ))}
    </div>
  );
}

function FooterSiteLinksNav() {
  return (
    <ul className="flex flex-col desktop-grid:flex-row w-full desktop-grid:w-auto justify-center gap-6 mb-2">
      {FooterSiteLinks.map((item, i) => (
        <li key={i}>
          <Link
            prefetch={false}
            href={item.href}
            className="text-secondary-400 hover:text-light transition-colors"
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default Footer;
